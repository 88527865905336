import React from 'react';
import VlbPage from './VlbPage';
import { withRouter } from '../../common/components/routing/Router';
import { Path } from '../container/Path';
import { Container as PageContainer } from "../../index";

import { withSecurity } from '../../common/security/Security';
import { Role } from '../user/Role';
import Identity from '../user/Identity';

import { Container, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Dropdown } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { DataProvider } from '../../common/model/DataProvider';
import ReduxComponentWrapper from '../../common/components/widgets/reduxConnected/ReduxComponentWrapper';
import { TablePage } from '../../common/components/widgets/table/TablePage';
import { IRestDataSourceParams } from '../../common/dataSource/IRestDataSourceParams';
import { DataColumn } from '../../common/components/widgets/table/column/DataColumn';
import { BasicSorter } from '../../common/components/widgets/table/sorter/BasicSorter';
import { BasicFilter } from '../../common/components/widgets/table/filter/BasicFilter';
import { ActionColumn } from '../../common/components/widgets/table/column/ActionColumn';
import { AxiosResponse, AxiosError } from 'axios';
import Util from '../custom/Util';
import { BasicPaging } from '../../common/components/widgets/table/paging/BasicPaging';
import { TextInput } from '../../common/components/widgets/form/input/TextInput';
import { LoginText, ILoginText } from '../models/LoginText';
import { TextAreaInput } from '../../common/components/widgets/form/input/TextAreaInput';
import { Redirect } from 'react-router-dom';
import { DropdownInput } from '../../common/components/widgets/form/input/DropdownInput';
import { DropdownFilter } from '../../common/components/widgets/table/filter/DropdownFilter';

@withRouter(Path.LOGIN_TEXT.toString(), PageContainer)
@withSecurity([Role.ADMIN.toString()], Identity, Path.AFFILIATES_STOCK)
class LoginTextPage extends VlbPage {
  private submitted = false;
  private modalTitle = "Add new";
  private model = new LoginText(false);
  private dataProvider = new DataProvider<ILoginText>();

  private loginTextInputs: { [attr: string]: React.RefObject<any> } = {
    content: React.createRef(),
    level: React.createRef()
  };

  constructor(props: any) {
    super(props);

    this.delete = this.delete.bind(this);
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.readInputs = this.readInputs.bind(this);
    this.fillInputsWithErrors = this.fillInputsWithErrors.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setInputValues = this.setInputValues.bind(this);

    this.state = {
      modalDelete: false,
      currentModelDelete: {},
      modalFormOpen: false,
      modalEdit: false,
      reload: false,
      isCreate: true,

      modalFile: false,
      currentModelFile: '',
      level: '',
    };
  }

  pageTitle() {
    return "All texts " + super.pageTitle();
  }

  getDestroyableMembers() {
    return [
    ];
  }

  delete(row?: any) {
    this.setState(prevState => ({
      modalDelete: !prevState.modalDelete,
      currentModelDelete: row
    }));
  }

  add() {
    this.modalTitle = "Add new ";
    this.model.changeScenario(this.state.scenarios ? this.state.scenarios.create : "default");

    this.setState(prevState => ({
      modalFormOpen: !prevState.modalFormOpen,
      isCreate: true
    }));
  }

  edit(row?: any) {
    this.modalTitle = "Edit ";
    this.model.changeScenario(this.state.scenarios ? this.state.scenarios.update : "default");

    this.model.loadById(row.id)
      .then((response: any) => {
        this.model.setFromPlainObject(response);
        this.setInputValues(response);

      })
      .catch((error: AxiosError) => {
        Util.notification("error", "Data could not be loaded", 1500);
      });

    this.setState(prevState => ({
      isCreate: false,
      modalFormOpen: !prevState.modalFormOpen
    }));
  }

  setInputValues(values: any) {

    let inputs = this.loginTextInputs;

    for (let key in values) {
      if (inputs[key] && inputs[key].current) {
        inputs[key].current.setValue(values[key]);
      }
    }
  }

  protected handleSubmit(event: React.MouseEvent<HTMLElement>): void {
    let promise: Promise<AxiosResponse>;
    this.submitted = true;

    if (this.validate(event)) {

      if (this.state.isCreate) {
        promise = this.model.createNew();
      } else {
        promise = this.model.update(this.model);
      }

      promise.then((response: AxiosResponse) => {

        Util.notification("success", "Success.", 2000);
        this.setState({ reload: true, modalFormOpen: false, modalEdit: false })

      })
        .catch((error: any) => {
          const aError: any = error;

          switch (aError.response.status) {
            case 422:

              if (aError.response && aError.response.data && aError.response.data.errors) {
                const respErrors: any[] = aError.response.data.errors;
                const errors: { [attr: string]: string } = {};

                respErrors.forEach((error: any) => {
                  errors[error.fieldName] = error.errorMessage;
                });

                this.fillInputsWithErrors(errors, this.loginTextInputs);
              }
              break;
            default:

              Util.notification("error", "Error.", 2000);
              break;
          }

          this.setState({ reload: true })
        });
    }
  }


  private fillInputsWithErrors(errors: { [attr: string]: string }, inputs: any) {

    for (const key in errors) {
      if (errors.hasOwnProperty(key) && inputs[key] !== undefined && inputs[key].current) {
        inputs[key].current.setError(errors[key]);
      }
    }
  }

  private validate(event: any): boolean {
    this.readInputs();

    let inputs = this.loginTextInputs;
    console.log(inputs)
    const valid = this.model.validate();
    console.log(valid)

    if (this.submitted && !valid) {
      const errors: { [attr: string]: string } = this.model.getErrors();
      this.fillInputsWithErrors(errors, inputs);
    }

    event.preventDefault();

    return valid;
  }

  private readInputs(): any {

    const plainObject = {};
    const inputs = this.loginTextInputs;

    for (const key in inputs) {
      if (inputs.hasOwnProperty(key) && inputs[key].current) {
        plainObject[key] = inputs[key].current.getValue();
        console.log(inputs[key])
        console.log(inputs[key].current.getValue())
        inputs[key].current.removeError();
      }
    }

    this.model.setFromPlainObject(plainObject);
    console.log(this.model)

    return plainObject;
  }

  info(row?: any) {
    if (row.intercompany_price_information) {
      this.setState(prevState => ({
        modalFile: !prevState.modalFile,
        currentModelFile: row
      }));
    } else {
      Util.notification("info", "No information", 1500)
    }
  }


  setLevel(id, edit: boolean = false) {

    let level
    if (edit) {
      level = id
    } else {
      level = id.target.value
    }

    this.setState({
      level
    })
  }


  renderContent() {

    let levelList = [
      { id: "info", name: "info" },
      { id: "warning", name: "warning" },
      { id: "danger", name: "danger" },
    ];

    let levelFilterList = {};
    for (let i = 0; i < levelList.length; i++) {
      levelFilterList[levelList[i].id] = levelList[i].name;
    }

    const textColumns: any[] = [
      new DataColumn('Content', 'content', 40, new BasicSorter(), new BasicFilter(false, 'Search content ', 4)),
      new DataColumn('Level', 'level', 20, new BasicSorter(), new DropdownFilter(levelFilterList, 'All levels', 4)),
      new DataColumn('Created at', 'created_at', 20, new BasicSorter()),
      new ActionColumn(null,
        (row: Object) => {
          this.delete(row)
        },
        (row: Object) => {
          this.edit(row)
        },
        10,
      )
    ];

    let info;
    info = (
      <Modal centered size="lg" isOpen={this.state.modalFile} fade={false} toggle={() => this.setState({ modalFile: !this.state.modalFile })}>
        <ModalHeader toggle={() => this.setState({ modalFile: !this.state.modalFile })}>{this.state.currentModelFile.name} log</ModalHeader>
        <ModalBody>
          {this.state.currentModelFile ? this.state.currentModelFile.intercompany_price_information : null}
        </ModalBody>
        <ModalFooter>
          <Button outline color="secondary" onClick={() => this.setState({ modalFile: !this.state.modalFile })}>Close</Button>
        </ModalFooter>
      </Modal>
    )

    let modal;
    modal = (
      <Modal centered size="md" isOpen={this.state.modalFormOpen} fade={false} toggle={() => this.add()}>
        <>
          <ModalHeader toggle={() => this.add()}>{this.modalTitle} user</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <TextAreaInput
                  rows={5}
                  label="Login screen text"
                  id="content"
                  placeholder="Text"
                  ref={this.loginTextInputs.content}
                />
              </FormGroup>
              <FormGroup>
                <DropdownInput
                  listItems={levelList}
                  label="Text level"
                  id="level"
                  placeholder="Level"
                  noEmptyOption={false}
                  ref={this.loginTextInputs.level}
                  onChange={(e) => this.setLevel(e, false)}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button outline color="secondary" onClick={() => this.setState({ modalFormOpen: !this.state.modalFormOpen })}>Cancel</Button>
            <Button outline color="primary" onClick={this.handleSubmit}>{this.state.isCreate ? "Add" : "Update"}</Button>
          </ModalFooter>
        </>
      </Modal>
    )

    let deleteModal;
    deleteModal = (
      <Modal centered size="md" isOpen={this.state.modalDelete} fade={false} toggle={this.delete}>
        <ModalHeader toggle={this.delete}>{this.state.currentModelDelete.name}</ModalHeader>
        <ModalBody>Are you sure you want to delete this text?</ModalBody>
        <ModalFooter>
          <Button outline color="secondary" onClick={() => this.setState({ modalDelete: !this.state.modalDelete })}>Cancel</Button>
          <Button outline color="primary" onClick={() =>
            (this.model).del(this.state.currentModelDelete["id"]).then(() => {
              this.setState(prevState => ({
                modalDelete: !prevState.modalDelete,
              }));
            })
          }>Delete</Button>
        </ModalFooter>
      </Modal>
    )

    const paging = new BasicPaging({
      pageSize: 15
    });

    return (
      <Container fluid className="page-content">

        <div className="title-box">
          <h1 className="page-title">Login text</h1>
          <div className="add-new"><Button color="primary" onClick={() => this.add()}><FontAwesomeIcon icon={faPlus} /> Add text</Button></div>
        </div>

        <div className="element-box">
          <ReduxComponentWrapper component={TablePage} componentPropsCallback={(state: any) => ({
            provider: this.dataProvider,
            loader: [6, 15],
            searchCallback: (params: IRestDataSourceParams): void => {
              if (params.filters) {
                params.filters.push({
                  attr: "status_id",
                  operator: "eq",
                  val: 1
                })
              } else {
                params.filters = [
                  {
                    attr: "status_id",
                    operator: "eq",
                    val: 1
                  }
                ];
              }
              this.dataProvider.refreshWithHeaders(
                this.model.getListPlain({
                  ...params,
                  sort: {
                    attr: "created_at",
                    ascending: false
                  }
                })
              )
            },
            columns: textColumns,
            paging: paging
          })} />

          {modal}
          {deleteModal}
          {info}

        </div>
      </Container>
    );
  }
}

export default LoginTextPage;

