import React from 'react';
import VlbPage from './VlbPage';
import { withRouter } from '../../common/components/routing/Router';
import { Path } from '../container/Path';
import { Container as PageContainer } from "../../index";
import { withSecurity } from '../../common/security/Security';
import { Role } from '../user/Role';
import Identity from '../user/Identity';
import { Container, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { User, IUser } from '../models/User';
import { DataProvider } from '../../common/model/DataProvider';
import ReduxComponentWrapper from '../../common/components/widgets/reduxConnected/ReduxComponentWrapper';
import { TablePage } from '../../common/components/widgets/table/TablePage';
import { IRestDataSourceParams } from '../../common/dataSource/IRestDataSourceParams';
import { DataColumn } from '../../common/components/widgets/table/column/DataColumn';
import { BasicSorter } from '../../common/components/widgets/table/sorter/BasicSorter';
import { BasicFilter } from '../../common/components/widgets/table/filter/BasicFilter';
import { ActionColumn } from '../../common/components/widgets/table/column/ActionColumn';
import { AxiosResponse, AxiosError } from 'axios';
import Util from '../custom/Util';
import { BasicPaging } from '../../common/components/widgets/table/paging/BasicPaging';
import { PasswordInput } from '../../common/components/widgets/form/input/PasswordInput';
import { TextInput } from '../../common/components/widgets/form/input/TextInput';
import { EmailInput } from '../../common/components/widgets/form/input/EmailInput';
import { DropdownInput } from '../../common/components/widgets/form/input/DropdownInput';
import { Affiliate } from '../models/Affiliate';
import { CustomActionColumn } from '../custom/CustomActionColumn';

@withRouter(Path.USERS.toString(), PageContainer)
@withSecurity([Role.ADMIN.toString()], Identity, Path.AFFILIATES_STOCK)
class UsersPage extends VlbPage {

  private submitted = false;
  private modalTitle = "Add new";
  private model = new User(false);
  private dataProvider = new DataProvider<IUser>();

  private affiliateModel: Affiliate = new Affiliate();

  private usersInputs: { [attr: string]: React.RefObject<any> } = {
    username: React.createRef(),
    email: React.createRef(),
    item_name: React.createRef(),
    abbreviation: React.createRef(),
    password: React.createRef(),
    affiliate_id: React.createRef(),
  };

  constructor(props: any) {
    super(props);

    this.delete = this.delete.bind(this);
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.readInputs = this.readInputs.bind(this);
    this.fillInputsWithErrors = this.fillInputsWithErrors.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setInputValues = this.setInputValues.bind(this);

    this.state = {
      modalDelete: false,
      currentModelDelete: {},
      modalFormOpen: false,
      modalEdit: false,
      reload: false,
      isCreate: true,
      affiliateList: [],
    };
  }

  pageTitle() {
    return "Users" + super.pageTitle();
  }

  getDestroyableMembers() {
    return [
    ];
  }

  delete(row?: any) {
    this.setState(prevState => ({
      modalDelete: !prevState.modalDelete,
      currentModelDelete: row
    }));
  }

  add() {
    this.modalTitle = "Add new ";
    this.model.changeScenario(User.SCENARIO.CREATE);

    this.setState(prevState => ({
      modalFormOpen: !prevState.modalFormOpen,
      isCreate: true
    }));
  }

  edit(row?: any) {
    this.modalTitle = "Edit ";
    this.model.changeScenario(User.SCENARIO.UPDATE);

    this.model.loadById(row.id)
      .then((response: any) => {
        this.model.setFromPlainObject(response);
        this.setInputValues(response);

      })
      .catch((error: AxiosError) => {
        Util.notification("error", "Data could not be loaded", 1500);
      });

    this.setState(prevState => ({
      isCreate: false,
      modalFormOpen: !prevState.modalFormOpen
    }));
  }

  setInputValues(values: any) {

    let inputs = this.usersInputs;

    for (let key in values) {
      if (inputs[key] && inputs[key].current) {
        inputs[key].current.setValue(values[key]);
      }
    }
  }

  protected handleSubmit(event: React.MouseEvent<HTMLElement>): void {
    let promise: Promise<AxiosResponse>;
    this.submitted = true;

    if (this.validate(event)) {

      if (this.state.isCreate) {
        promise = this.model.createNew();
      } else {
        promise = this.model.update(this.model);
      }

      promise.then((response: AxiosResponse) => {

        Util.notification("success", "Success.", 2000);
        this.setState({ reload: true, modalFormOpen: false, modalEdit: false })

      })
        .catch((error: any) => {
          const aError: any = error;

          switch (aError.response.status) {
            case 422:

              if (aError.response && aError.response.data && aError.response.data.errors) {
                const respErrors: any[] = aError.response.data.errors;
                const errors: { [attr: string]: string } = {};

                respErrors.forEach((error: any) => {
                  errors[error.fieldName] = error.errorMessage;
                });

                this.fillInputsWithErrors(errors, this.usersInputs);
              }
              break;
            default:

              Util.notification("error", "Error.", 2000);
              break;
          }

          this.setState({ reload: true })
        });
    }
  }


  private fillInputsWithErrors(errors: { [attr: string]: string }, inputs: any) {

    for (const key in errors) {
      if (errors.hasOwnProperty(key) && inputs[key] !== undefined && inputs[key].current) {
        inputs[key].current.setError(errors[key]);
      }
    }
  }

  private validate(event: any): boolean {
    this.readInputs();

    let inputs = this.usersInputs;
    const valid = this.model.validate();

    if (this.submitted && !valid) {
      const errors: { [attr: string]: string } = this.model.getErrors();
      this.fillInputsWithErrors(errors, inputs);
    }

    event.preventDefault();

    return valid;
  }

  private readInputs(): any {

    const plainObject = {};
    const inputs = this.usersInputs;

    for (const key in inputs) {
      if (inputs.hasOwnProperty(key) && inputs[key].current) {
        plainObject[key] = inputs[key].current.getValue();
        inputs[key].current.removeError();
      }
    }

    this.model.setFromPlainObject({ ...this.model.asPlainObject(), ...plainObject });

    return plainObject;
  }

  componentDidMount() {
    this.affiliateModel.getListPlain({})
      .then((response: AxiosResponse) => {

        this.setState({
          affiliateList: response.data
        })

      }).catch((e: AxiosError) => {
        Util.notification("error", "Affiliate list cannot be loaded", 1500);
      });

    super.componentDidMount();
  }

  renderContent() {

    let affiliateList = [];
    if (this.state.affiliateList) {
      for (var i = 0; i < this.state.affiliateList.length; i++) {

        affiliateList.push({
          id: this.state.affiliateList[i].id,
          name: this.state.affiliateList[i].abbreviation,
        });

      }
    }

    if (this.usersInputs["item_name"].current) {

      if (this.usersInputs["item_name"].current.getValue() == 'user') {
        affiliateList = affiliateList.filter(affiliate => (
          affiliate.id == Identity.affiliate_id))
      }
    }



    let rolesList = Object.values(Role).map((single) => (
      { id: single, name: single }
    ));

    let roleFilter: any = Role
    delete roleFilter.GUEST;

    const usersColumns: any[] = [
      new DataColumn('Username', 'username', 25, new BasicSorter(), new BasicFilter(false, 'Search username', 4)), //isnumber, placeholder, colsize
      new DataColumn('Abbreviation', 'abbreviation', 15, new BasicSorter()),
      new DataColumn('Email', 'email', 25, new BasicSorter(), new BasicFilter(false, 'Search email', 8)),
      new DataColumn('Affiliate', 'affiliate.name', 25, new BasicSorter()),
      new DataColumn('Role', 'item_name', 10, new BasicSorter()),
    ];

    if (Identity.role === Role.ADMIN) {
      usersColumns.push(
        new CustomActionColumn(null,
          (row: Object) => {
            this.delete(row)
          },
          (row: Object) => {
            this.edit(row)
          }, 10, true)
      )
    }

    let modal;
    modal = (
      <Modal centered size="md" isOpen={this.state.modalFormOpen} fade={false} toggle={() => this.add()}>
        <>
          <ModalHeader toggle={() => this.add()}>{this.modalTitle} user</ModalHeader>
          <ModalBody>
            <Form autocomplete="off">
              <FormGroup>
                <TextInput
                  autoComplete="off"
                  label="Username"
                  id="u_username"
                  placeholder="Username"
                  ref={this.usersInputs.username}
                />
              </FormGroup>
              <FormGroup>
                <EmailInput
                  label="Email"
                  id="u_email"
                  placeholder="Email"
                  ref={this.usersInputs.email}
                />
              </FormGroup>
              <FormGroup>
                <DropdownInput
                  listItems={rolesList}
                  label="Role"
                  id="u_role"
                  placeholder="Role"
                  ref={this.usersInputs.item_name}
                  onChange={e => this.forceUpdate()}
                />
              </FormGroup>
              <FormGroup>
                <DropdownInput
                  listItems={affiliateList}
                  label="Affiliate abbreviation"
                  id="u_affiliate"
                  placeholder="Affiliate abbreviation"
                  noEmptyOption={false}
                  ref={this.usersInputs.affiliate_id}
                />
              </FormGroup>
              <FormGroup>
                <TextInput
                  label="Abbreviation"
                  id="u_abbreviation"
                  placeholder="Abbreviation"
                  ref={this.usersInputs.abbreviation}
                />
              </FormGroup>
              <FormGroup>
                <PasswordInput
                  autoComplete="new-password"
                  label="Password"
                  id="u_password"
                  placeholder="Password"
                  ref={this.usersInputs.password}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button outline color="secondary" onClick={() => this.setState({ modalFormOpen: !this.state.modalFormOpen })}>Cancel</Button>
            <Button outline color="primary" onClick={this.handleSubmit}>{this.state.isCreate ? "Add" : "Update"}</Button>
          </ModalFooter>
        </>
      </Modal>
    )

    let deleteModal;
    deleteModal = (
      <Modal centered size="md" isOpen={this.state.modalDelete} fade={false} toggle={this.delete}>
        <ModalHeader toggle={this.delete}>{this.state.currentModelDelete["username"]}</ModalHeader>
        <ModalBody>Are you sure you want to delete this user?</ModalBody>
        <ModalFooter>
          <Button outline color="secondary" onClick={() => this.setState({ modalDelete: !this.state.modalDelete })}>Cancel</Button>
          <Button outline color="primary" onClick={() =>
            (this.model).del(this.state.currentModelDelete["id"]).then(() => {
              this.setState(prevState => ({
                modalDelete: !prevState.modalDelete,
              }));
            })
          }>Delete</Button>
        </ModalFooter>
      </Modal>
    )

    const paging = new BasicPaging({
      pageSize: 15
    });

    return (
      <Container fluid className="page-content">

        <div className="title-box">
          <h1 className="page-title">Users</h1>
          <div className="add-new"><Button color="primary" onClick={() => this.add()}><FontAwesomeIcon icon={faPlus} /> Add user</Button></div>
        </div>

        <div className="element-box">
          <ReduxComponentWrapper component={TablePage} componentPropsCallback={(state: any) => ({
            provider: this.dataProvider,
            loader: [6, 15],
            searchCallback: (params: IRestDataSourceParams): void => {

              let filters = params.filters || [];

              if (Identity.affiliate_id) {
                filters.push({
                  attr: "affiliate_id",
                  operator: "eq",
                  val: Identity.affiliate_id
                })
              }


              this.dataProvider.refreshWithHeaders(
                this.model.getListPlain({
                  ...params, sort: {
                    attr: "users.created_at",
                    ascending: false
                  },
                  filters
                })
              )
            },
            columns: usersColumns,
            paging: paging
          })} />

          {modal}
          {deleteModal}

        </div>
      </Container>
    );
  }
}

export default UsersPage;

