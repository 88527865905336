import React from 'react';
import VlbPage from './VlbPage';
import { withRouter } from '../../common/components/routing/Router';
import { Path } from '../container/Path';
import { Container as PageContainer } from "../../index";
import { withSecurity } from '../../common/security/Security';
import { Role } from '../user/Role';
import Identity from '../user/Identity';
import { Container, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { DataProvider } from '../../common/model/DataProvider';
import ReduxComponentWrapper from '../../common/components/widgets/reduxConnected/ReduxComponentWrapper';
import { TablePage } from '../../common/components/widgets/table/TablePage';
import { IRestDataSourceParams } from '../../common/dataSource/IRestDataSourceParams';
import { DataColumn } from '../../common/components/widgets/table/column/DataColumn';
import { BasicSorter } from '../../common/components/widgets/table/sorter/BasicSorter';
import { ActionColumn } from '../../common/components/widgets/table/column/ActionColumn';
import { AxiosResponse, AxiosError } from 'axios';
import Util from '../custom/Util';
import { BasicPaging } from '../../common/components/widgets/table/paging/BasicPaging';
import { TextInput } from '../../common/components/widgets/form/input/TextInput';
import { Affiliate } from '../models/Affiliate';
import { IAffiliateContact, AffiliateContact } from '../models/AffiliateContact';
import { EmailInput } from '../../common/components/widgets/form/input/EmailInput';
import { DropdownInput } from '../../common/components/widgets/form/input/DropdownInput';
import { DropdownFilter } from '../../common/components/widgets/table/filter/DropdownFilter';
import { BasicFilter } from '../../common/components/widgets/table/filter/BasicFilter';
import { CustomActionColumn } from '../custom/CustomActionColumn';

@withRouter(Path.AFFILIATES_CONTACTS.toString(), PageContainer)
@withRouter(Path.AFFILIATE_CONTACTS.toString(), PageContainer)
@withSecurity([Role.ADMIN.toString(), Role.USER.toString()], Identity, Path.AFFILIATES_STOCK)
class AffiliatesContactPage extends VlbPage {
  private submitted = false;
  private modalTitle = "Add new";
  private model = new AffiliateContact(false);
  private dataProvider = new DataProvider<IAffiliateContact>();

  private affiliateModel: Affiliate = new Affiliate();

  private affiliateContactInputs: { [attr: string]: React.RefObject<any> } = {
    name: React.createRef(),
    additional_info: React.createRef(),
    email: React.createRef(),
    phone_number: React.createRef(),
    affiliate_id: React.createRef(),
  };

  constructor(props: any) {
    super(props);

    this.delete = this.delete.bind(this);
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.readInputs = this.readInputs.bind(this);
    this.fillInputsWithErrors = this.fillInputsWithErrors.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setInputValues = this.setInputValues.bind(this);

    this.state = {
      modalDelete: false,
      currentModelDelete: {},
      modalFormOpen: false,
      modalEdit: false,
      reload: false,
      isCreate: true,
      modalFile: false,
      currentModelFile: '',
      affiliateList: [],
      affiliateIdContact: []
    };
  }

  pageTitle() {
    return "Affiliates contacts" + super.pageTitle();
  }

  getDestroyableMembers() {
    return [
    ];
  }

  delete(row?: any) {
    this.setState(prevState => ({
      modalDelete: !prevState.modalDelete,
      currentModelDelete: row
    }));
  }

  add() {
    this.modalTitle = "Add new ";
    this.model.changeScenario(this.state.scenarios ? this.state.scenarios.create : "default");

    this.setState(prevState => ({
      modalFormOpen: !prevState.modalFormOpen,
      isCreate: true
    }));
  }

  edit(row?: any) {

    this.modalTitle = "Edit ";
    this.model.changeScenario(this.state.scenarios ? this.state.scenarios.update : "default");

    this.model.loadById(row.id, row.affiliate_id)
      .then((response: any) => {
        this.model.setFromPlainObject(response);
        this.setInputValues(response);

      })
      .catch((error: AxiosError) => {
        Util.notification("error", "Data could not be loaded", 1500);
      });

    this.setState(prevState => ({
      isCreate: false,
      modalFormOpen: !prevState.modalFormOpen
    }));

    this.setAffiliateId(row.affiliate_id, true)
  }

  setInputValues(values: any) {

    let inputs = this.affiliateContactInputs;

    for (let key in values) {
      if (inputs[key] && inputs[key].current) {
        inputs[key].current.setValue(values[key]);
      }
    }
  }

  protected handleSubmit(event: React.MouseEvent<HTMLElement>): void {
    let promise: Promise<AxiosResponse>;
    this.submitted = true;

    if (this.validate(event)) {

      if (this.state.isCreate) {
        promise = this.model.createNew(this.model.affiliate_id);
      } else {
        promise = this.model.update(this.model, this.model.affiliate_id);
      }

      promise.then((response: AxiosResponse) => {

        Util.notification("success", "Success.", 2000);
        this.setState({ reload: true, modalFormOpen: false, modalEdit: false })

      })
        .catch((error: any) => {
          const aError: any = error;

          switch (aError.response.status) {
            case 422:

              if (aError.response && aError.response.data && aError.response.data.errors) {
                const respErrors: any[] = aError.response.data.errors;
                const errors: { [attr: string]: string } = {};

                respErrors.forEach((error: any) => {
                  errors[error.fieldName] = error.errorMessage;
                });

                this.fillInputsWithErrors(errors, this.affiliateContactInputs);
              }
              break;
            default:

              Util.notification("error", "Error.", 2000);
              break;
          }

          this.setState({ reload: true })
        });
    }
  }


  private fillInputsWithErrors(errors: { [attr: string]: string }, inputs: any) {

    for (const key in errors) {
      if (errors.hasOwnProperty(key) && inputs[key] !== undefined && inputs[key].current) {
        inputs[key].current.setError(errors[key]);
      }
    }
  }

  private validate(event: any): boolean {
    this.readInputs();

    let inputs = this.affiliateContactInputs;
    const valid = this.model.validate();

    if (this.submitted && !valid) {
      const errors: { [attr: string]: string } = this.model.getErrors();
      this.fillInputsWithErrors(errors, inputs);
    }

    event.preventDefault();

    return valid;
  }

  private readInputs(): any {

    const plainObject = {};
    const inputs = this.affiliateContactInputs;

    for (const key in inputs) {
      if (inputs.hasOwnProperty(key) && inputs[key].current) {
        plainObject[key] = inputs[key].current.getValue();
        inputs[key].current.removeError();
      }
    }

    this.model.setFromPlainObject({ ...this.model.asPlainObject(), ...plainObject });

    return plainObject;
  }

  componentDidMount() {
    this.affiliateModel.getListPlain({})
      .then((response: AxiosResponse) => {

        this.setState({
          affiliateList: response.data
        })

      }).catch((e: AxiosError) => {
        Util.notification("error", "Affiliate list cannot be loaded", 1500);
      });

    super.componentDidMount();
  }

  setAffiliateId(id, edit: boolean = false) {

    let affiliateId
    if (edit) {
      affiliateId = id
    } else {
      affiliateId = id.target.value
    }

    this.model.loadContactsById(affiliateId)
      .then((response) => {

        this.setState({
          affiliateIdContact: response
        })

      }).catch((e: AxiosError) => {
        Util.notification("error", "Affiliate cannot be loaded", 1500);
      });
  }

  renderContent() {

    let affiliateList = [];
    if (this.state.affiliateList) {
      for (var i = 0; i < this.state.affiliateList.length; i++) {

        affiliateList.push({
          id: this.state.affiliateList[i].id,
          name: this.state.affiliateList[i].abbreviation,
        });
      }

      if (Identity.affiliate_id) {
        affiliateList = affiliateList.filter(affiliate => (
          affiliate.id == Identity.affiliate_id))
      }

    }

    let affiliateFilterList = {};
    if (this.state.affiliateList) {
      for (let i = 0; i < this.state.affiliateList.length; i++) {
        affiliateFilterList[this.state.affiliateList[i].id] = this.state.affiliateList[i].name;
      }
    }

    const affiliateContactColumns: any[] = [
      new DataColumn('Affiliate', 'affiliate.name', 15, new BasicSorter()),
      new DataColumn('Affiliate', 'affiliate_id', 0, new BasicSorter(), new DropdownFilter(affiliateFilterList, this.props.match.params.id ? affiliateFilterList[this.props.match.params.id] : 'All affiliates', 8)), //isnumber, placeholder, colsize
      new DataColumn('Contact person', 'name', 20, new BasicSorter(), new BasicFilter(false, 'Search contact person', 4)),
      new DataColumn('Support scope information', 'additional_info', 15, new BasicSorter()),
      new DataColumn('Email address', 'email', 20, new BasicSorter()),
      new DataColumn('Telephone nr.', 'phone_number', 20, new BasicSorter()),
    ];


    if (Identity.role != Role.USER) {
      affiliateContactColumns.push(
        new CustomActionColumn(null,
          (row: IAffiliateContact) => {

            this.delete(row)

          },
          (row: IAffiliateContact) => {
            this.edit(row)

          },
          10,
          true
        )
      )
    }


    let modal;
    modal = (
      <Modal centered size="md" isOpen={this.state.modalFormOpen} fade={false} toggle={() => this.add()}>
        <>
          <ModalHeader toggle={() => this.add()}>{this.modalTitle} user</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <TextInput
                  label="Contact person"
                  id="c_name"
                  placeholder="Contact person"
                  ref={this.affiliateContactInputs.name}
                />
              </FormGroup>
              <FormGroup>
                <TextInput
                  label="Support scope information"
                  id="c_additional_info"
                  placeholder="Support scope information"
                  ref={this.affiliateContactInputs.additional_info}
                />
              </FormGroup>
              <FormGroup>
                <EmailInput
                  label="Email address"
                  id="c_email"
                  placeholder="Email address"
                  ref={this.affiliateContactInputs.email}
                />
              </FormGroup>
              <FormGroup>
                <TextInput
                  label="Telephone nr."
                  id="c_phone_number"
                  placeholder="Telephone nr."
                  ref={this.affiliateContactInputs.phone_number}
                />
              </FormGroup>
              <FormGroup>
                <DropdownInput
                  listItems={affiliateList}
                  label="Affiliate contact"
                  id="c_affiliate_contact"
                  placeholder="Affiliate contact"
                  noEmptyOption={false}
                  ref={this.affiliateContactInputs.affiliate_id}
                  onChange={(e) => this.setAffiliateId(e, false)}
                />
                {this.state.affiliateIdContact.length >= 5 ? <div className="invalid-feedback d-block">This affiliate alraedy have 5 contacts.</div> : null}
              </FormGroup>

            </Form>
          </ModalBody>
          <ModalFooter>
            <Button outline color="secondary" onClick={() => this.setState({ modalFormOpen: !this.state.modalFormOpen, affiliateIdContact: [] })}>Cancel</Button>
            <Button outline color="primary" disabled={this.state.affiliateIdContact.length >= 5 ? true : false} onClick={this.handleSubmit}>{this.state.isCreate ? "Add" : "Update"}</Button>
          </ModalFooter>
        </>
      </Modal>
    )

    let deleteModal;
    deleteModal = (
      <Modal centered size="md" isOpen={this.state.modalDelete} fade={false} toggle={this.delete}>
        <ModalHeader toggle={this.delete}>{this.state.currentModelDelete.name}</ModalHeader>
        <ModalBody>Are you sure you want to delete this contact?</ModalBody>
        <ModalFooter>
          <Button outline color="secondary" onClick={() => this.setState({ modalDelete: !this.state.modalDelete })}>Cancel</Button>
          <Button outline color="primary" onClick={() =>
            (this.model).del(this.state.currentModelDelete["id"], this.state.currentModelDelete["affiliate_id"]).then(() => {
              this.setState(prevState => ({
                modalDelete: !prevState.modalDelete,
              }));
            })
          }>Delete</Button>
        </ModalFooter>
      </Modal>

    )

    const paging = new BasicPaging({
      pageSize: 15
    });

    return (
      <Container fluid className="page-content affiliatecontactspage">

        <div className="title-box">
          <h1 className="page-title">Affiliates contacts</h1>
          {Identity.role == Role.ADMIN ?
            (<div className="add-new"><Button color="primary" onClick={() => this.add()}><FontAwesomeIcon icon={faPlus} /> Add affiliate contact</Button></div>
            ) : null}
        </div>

        <div className="element-box">
          <ReduxComponentWrapper component={TablePage} componentPropsCallback={(state: any) => ({
            provider: this.dataProvider,
            loader: [6, 15],
            searchCallback: (params: IRestDataSourceParams): void => {
              let filters = params.filters || [];

              if (this.props.match.params.id) {
                filters.push({
                  attr: "affiliate_id",
                  operator: "eq",
                  val: this.props.match.params.id
                })
              } else if (Identity.affiliate_id) {
                filters.push({
                  attr: "affiliate_id",
                  operator: "eq",
                  val: Identity.affiliate_id
                })
              }

              this.dataProvider.refreshWithHeaders(
                this.model.getListPlain({
                  ...params,
                  sort: {
                    attr: "created_at",
                    ascending: false
                  },
                  filters
                })
              )
            },
            columns: affiliateContactColumns,
            paging: paging
          })} />

          {modal}
          {deleteModal}

        </div>
      </Container>
    );
  }
}

export default AffiliatesContactPage;

