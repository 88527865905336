import React from "react";
import ReactExport from "react-export-excel";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

interface IExportExcel {
    exportList: any
}

export class ExportExcelArticle extends React.Component<IExportExcel, {}> {
    constructor(props) {
        super(props);
    }

    render() {
        let prepareForExport = []

        for (let i = 0; i < this.props.exportList.length; i++) {
            prepareForExport.push({
                key: this.props.exportList[i].id,
                name: this.props.exportList[i].affiliate.name,
                code: this.props.exportList[i].code,
                description: this.props.exportList[i].description,
                available_stock: this.props.exportList[i].available_stock,
                uom: this.props.exportList[i].uom,
                weight: Number(this.props.exportList[i].weight),
                cost_price: this.props.exportList[i].cost_price,
                replacement_value: Number(this.props.exportList[i].replacement_value),
                size: this.props.exportList[i].size
            })
        }


        return (
            <ExcelFile filename={'Affiliate Stock Export'} element={<Button color="export-button" className="py-0 fs-09"><FontAwesomeIcon icon={faFileExport} /> Export</Button>}>
                <ExcelSheet data={prepareForExport} name="Affiliate stock">
                    <ExcelColumn label="Affiliate" value="name" />
                    <ExcelColumn label="Article number" value="code" />
                    <ExcelColumn label="Article Description" value="description" />
                    <ExcelColumn label="Size" value="size" />
                    <ExcelColumn label="Available stock quantity" value="available_stock" />
                    <ExcelColumn label="U.O.M." value="uom" />
                    <ExcelColumn label="Weight" value="weight" />
                    <ExcelColumn label="Cost price" value="cost_price" />
                    <ExcelColumn label="Replacement value" value="replacement_value" />
                </ExcelSheet>
            </ExcelFile>
        );
    }
}